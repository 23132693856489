





















































import useContact from "@/use/contact";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
  },

  setup(_, { root }) {
    const { getRoleName, hasAccessTo, hasManyCompanies } = useUser({ root });
    const user = computed(() => root.$store.getters["user/getData"]);
    const { fetchUnreadFrom } = useContact({ root });

    const state = reactive({
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [] as any[],
      total: 0,
      contactTimer: null as any,
    });

    const model = reactive({
      company: null as any,
    });

    const unreadFrom = ref<{ id: string; count: number }[]>([]);
    const visibleIds = computed<string[]>(() =>
      state.items.map((item) => item.id)
    );

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { page, itemsPerPage } = state.options as DataOptions;

      const filter = {
        company: model.company ? model.company.id : undefined,
      };

      axiosInstance
        .get("contact", { params: { page, itemsPerPage, filter } })
        .then(({ data: { users, total } }) => {
          state.items = users;
          state.total = total;
        })
        .catch(() => []);
    };

    const fetchUnread = async () => {
      for (const id of visibleIds.value) {
        await fetchUnreadFrom(id)
          .then(({ data: { count } }) => {
            const item = unreadFrom.value.find((i) => i.id === id);

            if (item) item.count = count;
            else unreadFrom.value.push({ id, count });
          })
          .catch(console.log);
      }
    };

    const getUnreadFrom = (id: string) => {
      if (
        unreadFrom &&
        unreadFrom.value &&
        unreadFrom.value.find((i) => i.id === id)
      ) {
        return unreadFrom.value.find((i) => i.id === id)?.count as number;
      } else {
        return 0;
      }
    };

    const hasUnreadFrom = (id: string) => {
      return getUnreadFrom(id) > 0;
    };

    watch(model, () => (state.options.page = 1), { deep: true });
    watch(model, fetchData, { deep: true });
    watch(() => state.options, fetchData, { deep: true });
    watch(() => visibleIds.value, fetchUnread);

    onMounted(fetchData);

    watch(() => state.options, fetchUnread, { deep: true });
    onMounted(() => (state.contactTimer = setInterval(fetchUnread, 5000)));
    onBeforeUnmount(() =>
      state.contactTimer ? clearInterval(state.contactTimer) : void 0
    );

    return {
      hasAccessTo,
      hasManyCompanies,
      state,
      model,
      user,
      getUnreadFrom,
      hasUnreadFrom,
      getRoleName,
    };
  },
});
